import React, {useState} from "react"
import Images from "../../theme/images";
import axios from "axios";
import Modal from "../Modal";
import images from "../../theme/images";
import File from "../../components/File";

const DownloadPdf = props => {
    const {sendCatalog, downloadUrl, name} = props
    const [email, setEmail] = useState()
    const [secret, setSecret] = useState('8nNb2gNr6NwW!]y>8P)A;6B#%2m]_FG2$E%9')
    const [downloadFullPdf, setDownloadFullPdf] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showLoading, setShowLoading] = useState(true)
    const [hasError, setHasError] = useState(false)

    const handleForm = (e, sendCatalog) => {
        e.preventDefault();
        setDownloadFullPdf(false)
        sendData(sendCatalog)
    }

    function sendData(sendCatalog) {
        setShowModal(true)
        setHasError(false)
        setShowLoading(true)

        axios.post(`${process.env.API_URL}/subscriber`, {
            email: email,
            source: 'PRODUTO',
            sendCatalog: sendCatalog,
            secret: secret
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'bEI2vUjdqp5nHcwGVaFqD5dC3MNZzeqKadx1TwIL'
            }
        })
        .then(() => setHasError(false))
        .catch(() => setHasError(sendCatalog?true:false))
        .finally(() => setShowLoading(false))
    }

    function sendToEmail() {
        sendData(true)
        setDownloadFullPdf(true)
    }

    return (
        <form name="subscribeForm" method="POST" onSubmit={e => handleForm(e, sendCatalog)}>
            <p className="text-darkblue font-bold pb-2">
                {sendCatalog?"Receba o catálogo completo em seu e-mail:":"Baixe a página do produto:"}
            </p>
            <input type="hidden" value={secret} onChange={e => setSecret(e.target.value)} name="secret"/>
            <input className="w-full py-2 px-4 border-2 border-black block rounded" type="email" name="email" placeholder="Informe seu e-mail" onChange={e => setEmail(e.target.value)} value={email} required />
            <div className="flex flex-row-reverse p-10">
                <button type="submit" className="bg-gradient-to-r from-pink to-purple font-bold text-white text-xl border-2 border-white rounded-full pl-6 pr-2 py-1 shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
                    Download
                    <img loading="lazy" src={Images.iconChevronDoubleRight} alt="" className="float-right ml-2 pt-0.5" />
                </button>
                <img loading="lazy" src={Images.iconPdf} className="w-9 mr-5" alt=""/>
            </div>
            {
                showModal && (
                    <Modal>
                        <div className="text-center justify-center">
                            { (!showLoading && !sendCatalog && !downloadFullPdf) && (
                                <>
                                    <img loading="lazy" src={images.iconSuccess} alt="Sucesso" className="w-20 m-3 m-auto"/>
                                    <h2 className="text-xl font-bold py-4 text-darkpurple">Seu download está pronto!</h2>
                                    <p className="text-sm px-8 text-dark-gray">Você está baixando o conteúdo do produto <p className="font-bold inline">{name.toUpperCase()}</p>. Caso ele não tenha iniciado automaticamente <File href={downloadUrl} download={true} autostart className={"text-purple underline cursor-pointer"}>clique aqui</File>.</p>

                                    <div className="border border-light-gray bg-light-gray border-white m-10 rounded-2xl shadow-xl">
                                        <p className="text-sm text-center pt-5 text-purple">Que tal receber o<br/> <p className="font-bold inline">CATÁLOGO COMPLETO</p><br/>no seu e-mail?</p>
                                        <div className="p-3  mt-2 text-center space-x-4 md:block">
                                            <button className="mb-2 md:mb-0 text-dark-gray px-5 py-2 text-sm shadow-sm font-medium tracking-wider transition-all border rounded-full hover:shadow-lg" onClick={()=>setShowModal(false)}>agora não</button>
                                            <button className="mb-2 md:mb-0 bg-purple border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full transition-all hover:shadow-lg" onClick={()=>sendToEmail()}>SIM, ACEITO</button>
                                        </div>
                                    </div>
                                </>
                            )}
                            { (!showLoading && !hasError && (sendCatalog || downloadFullPdf)) && (
                                <>
                                    <img loading="lazy" src={images.iconSuccess} alt="Sucesso" className="w-20 m-3 m-auto"/>
                                    <h2 className="text-xl font-bold py-4 text-darkpurple">Sucesso</h2>
                                    <p className="text-sm px-8 text-dark-gray">Dentro de alguns minutos você receberá o catálogo em seu email. Caso não receba verifique sua caixa de spam.</p>
                                    <button className="mt-10 mb-2 md:mb-0 text-dark-gray px-5 py-2 text-sm shadow-sm font-medium tracking-wider transition-all border rounded-full hover:shadow-lg" onClick={()=>setShowModal(false)}>FECHAR</button>
                                </>
                            )}
                            { (showLoading) && (
                                <div className="text-center">
                                    <img loading="lazy" className="animate-spin w-12 m-auto" src={Images.iconLoading} alt=""/>
                                </div>
                            )}
                            { (!showLoading && hasError) && (
                                <>
                                    <h2 className="text-xl font-bold py-4 text-darkpurple">Opss... ocorreu um erro</h2>
                                    <p className="text-sm px-8 text-dark-gray">Por favor, tente novamente mais tarde.</p>
                                    <button className="mt-10 mb-2 md:mb-0 text-dark-gray px-5 py-2 text-sm shadow-sm font-medium tracking-wider transition-all border rounded-full hover:shadow-lg" onClick={()=>setShowModal(false)}>FECHAR</button>
                                </>
                            )}
                        </div>
                    </Modal>
                )
            }
        </form>
    )
}

export default DownloadPdf