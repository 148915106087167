import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import axios from "axios";

const File = ({ children, href, autostart, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      files: allFile(
        filter: { internal: { mediaType: { in: ["application/pdf", "application/zip", "application/octet-stream", "application/x-zip-compressed", "multipart/x-zip"]} } }
      ){
        edges {
          node {
            relativePath
            publicURL
            name
            extension
          }
        }
      }
    }
  `);

  const match = useMemo(
    () => data.files.edges.find(({ node }) => href === node.relativePath),
    [data, href]
  );

  if (!match) return null;

  const { node: { publicURL, extension, name } = {} } = match;

  if(autostart) {
    axios({
      url: publicURL,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
          "download",
          `${name}.${extension}`
      );
      document.body.appendChild(link);
      link.click();
    });
  }

  if(!children) {
    return <a href={publicURL} {...rest} />;
  }
  return <a href={publicURL} {...rest}>{children}</a>;
};

File.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default File;